




import { Component, Vue, Prop } from 'vue-property-decorator';
import { mainReportLst } from '@/modules/budget-request/components/js/budgetFormsLst';

@Component({ name: 'c-budg-report-lst' })
export default class CBudgetReportsList extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private curFormSelect!: any;

    private curForm: any = null;

    private created() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.curForm = this.curFormSelect;
        this.$watch('curFormSelect', function () {
            if (that.curFormSelect) { that.curForm = that.curFormSelect; }
        });
    }

    private get formDict() {
        const result: any[] = [];
        for (let i = 0; i < mainReportLst.length; i++) {
            const obj = this.setNameLang(mainReportLst[i]);
            obj.id = i;
            result.push(obj);
        }
        return result;
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private chgCurFormSelect() {
        if (!this.curForm) { return; }
        const url: any = this.curForm;
        this.$router.push(url);
    }
}
