<template>
  <div>
    <div class="filter-container">
      <b-dropdown class="filter-dropdown" variant="default" ref="drop" @show="filterOpen" :disabled = loading>
        <template #button-content>
          <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
        </template>
        <div>
          <div class="top-content">
            <span>Параметры фильтра</span>
            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
          </div>
            <!--------------- Плановый период ------------------->
          <div class="filter-block">
                <b-form-group label="Плановый период">
                    <multiselect
                        ref="bcPlanYear"
                        v-model="planPeriod"
                        track-by="name"
                        label="name"
                        placeholder="Выбрать год"
                        :options="years"
                        :searchable="false"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="periodChange()"
                    >
                    </multiselect>
                </b-form-group>
            </div>
            <!--------------- Область/район ------------------->
          <div class="filter-block" v-if="listReg !== null">
                <b-form-group label="Область/район">
                    <multiselect
                        v-model="selRegion"
                        track-by="name_ru"
                        label="text"
                        placeholder="Выбрать регион"
                        :options="listReg"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="regionChange()"
                        ref="bcRegion"
                    >
                    </multiselect>
                </b-form-group>
            </div>
            <!------------------Варианты----------------->
          <div class="filter-block">
            <b-form-group label="Версия бюджета">
                <multiselect
                    v-model="curVariant"
                    track-by="name"
                    label="name"
                    placeholder="Выбрать вверсию бюджета"
                    :options="variantLst"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    ref="bcVariant"
                    @input="variantChange()"
                >
                </multiselect>
            </b-form-group>
          </div>
            <!--------------- АБП ------------------->
          <div class="filter-block" v-if="curFilter !== undefined && curFilter.abpFilter.length > 0 && selecteds !== '2'">
                <b-form-group label="АБП">
                    <multiselect
                        ref="bcAbp"
                        v-model="selectedAbp"
                        track-by="text"
                        label="text"
                        :options="curFilter.abpFilter"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="updateABP">
                    </multiselect>
                </b-form-group>
            </div>
            <!--------------- Программа ------------------->
          <div class="filter-block" v-if="curFilter !== undefined && curFilter.prgList !== null && selecteds !== '2'">
                <b-form-group label="Бюджетная программа">
                    <multiselect
                        ref="bcPrg"
                        v-model="selectedPrg"
                        track-by="text"
                        label="text"
                        :options="selectedAbp ? selectedAbp.prgList : []"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="updatePRG">
                    </multiselect>
                </b-form-group>
            </div>
            <!--------------- Подпрограмма ------------------->
          <div class="filter-block" v-if="curFilter !== undefined && curFilter.pprList !== null && selecteds !== '2'">
                <b-form-group label="Бюджетная подпрограмма">
                    <multiselect
                        ref="bcPpr"
                        v-model="selectedPpr"
                        track-by="text"
                        label="text"
                        :options="selectedPrg ? selectedPrg.pprList : []"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="updatePPR">
                    </multiselect>
                </b-form-group>
            </div>
        </div>
        <div>
            <br>
        </div>
        <div class="filter-actions">
           <b-button style="margin-left: 250px" variant="success" :disabled="!loadCheck" @click="loadData()">Применить</b-button>
        </div>
        <div>
              <hr>
          </div>
          <!--------------- Специфика ------------------->
        <div class="filter-block" v-if="selecteds === '2' && spfList !== null">
              <b-form-group label="Специфика">
                  <b-form-select
                      ref="bcSpf"
                      v-model="selEconSpf"
                      :options="spfList"
                      value-field="spf"
                      @input="chgData()"
                      label-field="text"
                  >
                  </b-form-select>
              </b-form-group>
          </div>
        <!--------------- Вид программы ------------------->
        <div class="filter-block" v-if="selecteds === '0'">
            <b-form-group label="Вид программы">
              <b-form-radio-group
                  ref="bcDevelop"
                  id="radio-group-3"
                  v-model="develop"
                  :options="developes"
                  name="radio-options_2"
                  @input="chgData()"
              >
              </b-form-radio-group>
            </b-form-group>
        </div>
        <!--------------- Версия ------------------->
        <div>
              <br>
          </div>
        <div class="filter-block" v-if="selecteds !== '2'">
              <b-form-group >
                  <b-form-radio-group
                      ref="bcVersion"
                      id="radio-group-2"
                      v-model="version"
                      :options="versions"
                      name="radio-options_1"
                      @input="updateVersion()"
                  >
                  </b-form-radio-group>
              </b-form-group>
          </div>
      </b-dropdown>
      <div class="filter-block">
        <b-form-radio-group
            id="radio-group-1"
            v-model="selecteds"
            :options="options_"
            name="radio-options"
            :disabled="loading"
            @change="checkFilters"
        >
        </b-form-radio-group>
    </div>
    </div>
    <div class="table-container">
      <!--       хлебные крошки-->
    <div class="filter-breadcrumb">
        <span class="item-breadcrumb" v-if="!openFilterWindow && planPeriod" @click="openFilterByRef('bcPlanYear')">
            {{ planPeriod.name }}
        </span>
        <span class="item-breadcrumb" v-if="!openFilterWindow && selRegion" @click="openFilterByRef('bcRegion')">
            {{ curFilter.region }} - {{ selRegion.name_ru }}
        </span>
        <span class="item-breadcrumb" v-if="!openFilterWindow && curVariant" @click="openFilterByRef('bcVariant')">
            {{ refVariant }} - версия
        </span>
        <span class="item-breadcrumb" v-if="!openFilterWindow && selEconSpf && selecteds === '2'" @click="openFilterByRef('bcSpf')">
            {{ selEconSpf }}-СПФ
        </span>
        <span class="item-breadcrumb" v-if="!openFilterWindow && selectedAbp !== null && selectedAbp.abp !== '000' && selecteds !== '2'" @click="openFilterByRef('bcAbp')">
            {{ selectedAbp.abp }}-АБП
        </span>
        <span class="item-breadcrumb" v-if="!openFilterWindow && selectedPrg !== null && (selectedPrg.prg !== '000') && selecteds !== '2'" @click="openFilterByRef('bcPrg')">
            {{selectedPrg.prg}}-БП
        </span>
        <span class="item-breadcrumb" v-if="!openFilterWindow && selectedPpr !== null && (selectedPpr.ppr !== '000') && selecteds !== '2'" @click="openFilterByRef('bcPpr')">
            {{selectedPpr.ppr}}-БПП
        </span>
        <span class="item-breadcrumb" v-if="!openFilterWindow && selecteds === '0'" @click="openFilterByRef('bcDevelop')">
            Вид программы - {{ developes[develop].text }}
        </span>
        <span class="item-breadcrumb" v-if="!openFilterWindow && selecteds !== '2'" @click="openFilterByRef('bcVersion')">
            {{ refVersion }}
        </span>
    </div>
    </div>
    <div class="table-card">
      <template v-if="selecteds == 0">
        <cost-func ref="costfunc"
                   v-if="dictFunc !== null"
                   :year="curYear"
                   :region="selRegion.code"
                   :develop="develop"
                   :version="version"
                   :totals="totalFunc"
                   :dictTree="dictFunc"
                   :dataType="dataType"
                   :selectedAbp="selectedAbp"
                   :selectedPrg="selectedPrg"
                   :selectedPpr="selectedPpr"
        ></cost-func>
      </template>
      <template v-if="selecteds == 1">
        <cost-depart ref="costdepart"
                     v-if="dictDepart !== null"
                     :year="curYear"
                     :region="selRegion.code"
                     :version="version"
                     :totals="totalDepart"
                     :dictTree="dictDepart"
                     :dataType="dataType"
                     :selectedAbp="selectedAbp"
                     :selectedPrg="selectedPrg"
                     :selectedPpr="selectedPpr"
        ></cost-depart>
      </template>
      <template v-if="selecteds == 2">
        <cost-econ ref="costecon"
                   v-if="dictEcon !== null"
                   :year="curYear"
                   :region="selRegion.code"
                   :dictTree="dictEcon"
                   :dataType="dataType"
                   :totals="totalEcon"
                   :selectedSpf="selEconSpf"
        ></cost-econ>
      </template>
    </div>
    <loading
        :active="loading"
        is-full-screen
        spinner="bar-fade-scale"
        color="#6495ED"
    />

  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueElementLoading from 'vue-element-loading';
import CostFunc from '@/modules/budget/CostFunc';
import CostEcon from '@/modules/budget/CostEcon';
import CostDepart from '@/modules/budget/CostDepart';
import { CUR_YEAR } from '../budget-request/components/js/budgetCurYear';
import store from '@/services/store';
import { variantName } from '@/modules/budget/budgetCorrectHelper';


export default {
    name: 'CostReports',
    components: { CostFunc, CostEcon, CostDepart, 'loading': VueElementLoading },
    props: {
        year: String,
        obl: String,
        reg: String,
        dataType: Number
    },

    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'gr',
                    label: 'ФГ'
                },
                {
                    key: 'pgr',
                    label: 'ФПГ'
                },
                {
                    key: 'abp',
                    label: 'АБП'
                },
                {
                    key: 'prg',
                    label: 'БП'
                },
                {
                    key: 'ppr',
                    label: 'БПП'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'receipt_prev',
                    label: ''
                },
                {
                    key: 'plan',
                    label: ''
                },
                {
                    key: 'request1',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow1',
                    label: ''
                },
                {
                    key: 'deviation1',
                    label: ''
                },
                {
                    key: 'request2',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow2',
                    label: ''
                },
                {
                    key: 'deviation2',
                    label: ''
                },
                {
                    key: 'request3',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow3',
                    label: ''
                },
                {
                    key: 'deviation3',
                    label: ''
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            user: null,
            years: [],
            planPeriod: null,
            variantBase: [],
            curVariant: null,
            variantLst: [],
            listReg: null,
            totalFunc: null,
            dictFunc: null,
            totalDepart: null,
            dictDepart: null,
            totalEcon: null,
            dictEcon: null,
            spfList: null,
            selectedAbp: null,
            selectedPrg: null,
            selectedPpr: null,
            selEconSpf: null,
            views: [
                { value: null, text: 'Выберите отчет' },
                { value: 0, text: 'Функциональная' },
                { value: 1, text: 'Ведомственная' },
                { value: 2, text: 'Экономическая' }
            ],
            view: null,
            developes: [
                { value: 0, text: 'Текущая' },
                { value: 1, text: 'Развития' },
                { value: 2, text: 'Все' }
            ],
            develop: 2,
            versions: [
                { value: 0, text: 'АБП' },
                { value: 1, text: 'УЭБП' }
            ],
            version: 0,
            selecteds: '0',
            options_: [
                { text: 'Функциональная', value: '0' },
                { text: 'Ведомственная', value: '1' },
                { text: 'Экономическая', value: '2' }
            ],
            openFilterWindow: false,
            region: null,
            selRegion: null,
            curYear: null,
            cYear: CUR_YEAR,
            loading: false,
            isLoading: false,
            filterFunc: { curYear: null, year: null, region: null, variant: null, version: 0, abp: '000', prg: '000', ppr: '000', abpFilter: [], prgList: [], pprList: [], opFilter: true },
            filterDepart: { curYear: null, year: null, region: null, variant: null, version: 0, abp: '000', prg: '000', ppr: '000', abpFilter: [], prgList: [], pprList: [], opFilter: true },
            filterEcon: { curYear: null, year: null, region: null, variant: null, abpFilter: [], prgList: [], pprList: [], opFilter: false },
            firstLoad: true
        };
    },
    created() {
        const startDate = CUR_YEAR - 1;
        const endDate = CUR_YEAR + 1;
        for (let i = endDate; i >= startDate; i--) {
            this.years.push({name: `${i} - ${i + 2}`, year: i})
        }

        if ([1, 4].includes(this.dataType)) {
            this.cYear = new Date().getFullYear() + 1;
        }
        else {
            this.cYear = new Date().getFullYear();
        }
        for (const y of this.years) {
            if (y.year === this.cYear) {
                this.planPeriod = y;
                this.curYear = y.year;
                this.filterFunc.year = y.year;
                this.filterDepart.year = y.year;
                this.filterEcon.year = y.year;
            }
        }

        // this.$watch('userId', (newValue, oldValue) => {
        //     this.loadRegions();
        // });

    },
    async mounted() {
        await this.loadRegions();
        if (this.selRegion !== null) {
            this.filterFunc.region = this.selRegion.code;
            this.filterDepart.region = this.selRegion.code;
            this.filterEcon.region = this.selRegion.code;
            await this.loadVariants();
        }
        if (this.curVariant !== null) {
            this.filterFunc.variant = this.curVariant.variant_uuid;
            this.filterDepart.variant = this.curVariant.variant_uuid;
            this.filterEcon.variant = this.curVariant.variant_uuid;
        }
        if  ((this.selRegion !== null) && (this.curVariant !== null)) {
            await this.loadData();
        }
    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        curFilter() {
            if (this.selecteds === '0') {
                return this.filterFunc;
            }
            if (this.selecteds === '1') {
                return this.filterDepart;
            }
            if (this.selecteds === '2') {
                return this.filterEcon;
            }
        },
        userId() {
            return this.$store.getters.user_uuid;
        },
        loadCheck() {
            return !((this.curFilter.variant === undefined || this.curFilter.variant === null) || this.curFilter.year === undefined || this.curFilter.year === null || this.curFilter.region === undefined || this.curFilter.region === null);
        },
        refVariant() {
            if (this.curFilter.variant !== null) {
                return this.curVariant.name;
            } else {
                return '';
            }
        },
        refVersion() {
            if ((this.selecteds !== 2) && (this.curFilter.version !== undefined)) {
                return this.versions[this.curFilter.version].text;
            } else {
                return '';
            }
        }
    },
    methods: {
        // ------------- Варианты -------------
        async loadVariants() {
            this.variantBase = [];
            this.variantLst = [];
            this.curVariant = null;
            const param = { dataType: this.dataType, region: this.curFilter.region, curYear: parseInt(this.curFilter.year.toString()) };
            try {
                const result = await fetch('/api-py/get-budg-variants-list/' + encodeURI(JSON.stringify(param)));
                if (result.status === 200) {
                    const json = await result.json();
                    const getVariantName = variantName;
                    for (const el of json) {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        el.name_ru = getVariantName(el);
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        el.name_kk = getVariantName(el, 'name_kk');
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        el.name_en = getVariantName(el, 'name_en');

                    }
                    this.variantBase = json;
                    if (this.variantBase.length > 0) {
                        for (const el of this.variantBase) {
                            this.variantLst.push(this.setNameLang(el));
                        }
                        this.curVariant = this.variantLst[0];
                        if (this.selecteds === '0') {
                            this.filterFunc.variant = this.curVariant.variant_uuid;
                        }
                        if (this.selecteds === '1') {
                            this.filterDepart.variant = this.curVariant.variant_uuid;
                        }
                        if (this.selecteds === '2') {
                            this.filterEcon.variant = this.curVariant.variant_uuid;
                        }

                    } else {
                        this.makeToast('warning', 'Расходы --> Версия бюджета', 'Нет доступных версий бюджета');
                    }
                } else {
                    this.makeToast('danger', 'get-budget-variants-lst', `${result.status} - ${result.statusText}`);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-budget-variants-lst', error.toString());
            }
        },
        async loadRegions() {
            try {
                if (this.userId !== undefined) {
                    const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.userId);
                    if (response.status === 200) {
                        this.listReg = await response.json();
                        for (const item of this.listReg) {
                            if (item.code === this.reg) {
                                this.selRegion = item;
                                break;
                            }
                        }
                    }
                }

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов
        async filterOpen() {
            if (!Array.isArray(this.listReg)) {
                await this.loadRegions();
            }
        },
        async loadData() {
            try {
                this.loading = true;
                await this.listLoads(parseInt(this.selecteds));
                if (this.selecteds === '0') {
                    if (this.dictFunc === null || !this.isLoading) {
                        this.dictFunc = null;
                        await this.loadDictFunc();
                        this.isLoading = true;
                    }
                }
                if (this.selecteds === '1') {
                    if (this.dictDepart === null || !this.isLoading) {
                        this.dictDepart = null;
                        await this.loadDictDep();
                        this.isLoading = true;
                    }
                }
                if (this.selecteds === '2') {
                    if (this.dictEcon === null || !this.isLoading) {
                        this.dictEcon = null;
                        await this.loadDictEcon();
                        this.isLoading = true;
                    }
                }
                this.chgData();
            } finally {
                this.loading = false;
            }
        },
        async loadDictFunc() {
            try {
                const response = await fetch('/api-py/cost-func-forms/' + this.curFilter.year + '/' + this.curFilter.region + '/' + this.curFilter.variant + '/' + this.dataType + '/' + this.curFilter.abp + '/' + this.curFilter.prg + '/' + this.curFilter.ppr);
                const items = await response.json();
                this.dictFunc = null;
                this.dictFunc = items.func;
                this.totalFunc = items.totals_func;

                this.$set(this.totalFunc, 'rp1', this.totalFunc.request1);
                this.$set(this.totalFunc, 'rp2', this.totalFunc.request2);
                this.$set(this.totalFunc, 'rp3', this.totalFunc.request3);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictFunc', error.toString());
            }
        },
        async listLoads(view) {
            try {
                if (this.curFilter.opFilter) {
                    const response = await fetch('/api-py/filters-abp-prg-ppr/' + this.curFilter.year + '/' + this.curFilter.region + '/' + this.curFilter.variant + '/' + this.dataType);
                    const items = await response.json();
                    await this.loadFilters(new Map(Object.entries(items.abpList)), view);
                    this.selectedAbp = this.curFilter.abpFilter[0];
                    this.selectedPrg = this.selectedAbp.prgList[0];
                    this.selectedPpr = this.selectedAbp.prgList[0].pprList[0];
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictDep', error.toString());
            }
        },
        async checkFilters() {
            this.loadVariants();
            for (const row of this.curFilter.abpFilter) {
                if (row.abp === this.curFilter.abp) {
                    this.selectedAbp = row;
                }
            }
            for (const row of this.curFilter.prgList) {
                if (row.prg === this.curFilter.prg) {
                    this.selectedPrg = row;
                }
            }
            for (const row of this.curFilter.pprList) {
                if (row.ppr === this.curFilter.ppr) {
                    this.selectedPpr = row;
                }
            }
            if (this.curFilter.year !== null) {
                for (const y of this.years) {
                    if (y.year === this.curFilter.year) {
                        this.planPeriod = y;
                    }
                }
            }
            if (this.curFilter.region !== null) {
                for (const y of this.listReg) {
                    if (y.code === this.curFilter.region) {
                        this.selRegion = y;
                    }
                }
            }
            this.curVariant = null;
            if (this.curFilter.variant !== null) {
                for (const y of this.variantLst) {
                    if (y.variant_uuid === this.curFilter.variant) {
                        this.curVariant = y;
                    }
                }
            }
            this.version = this.curFilter.version;
            this.chgData();
            if ((this.dictDepart === null || this.dictEcon === null) && (this.curFilter.variant !== null)) {
                await this.loadData();
            }
        },
        async loadDictDep() {
            try {
                const response = await fetch('/api-py/cost-depart-forms/' + this.curFilter.year + '/' + this.curFilter.region + '/' + this.curFilter.variant + '/' + this.dataType + '/' + this.curFilter.abp + '/' + this.curFilter.prg + '/' + this.curFilter.ppr);
                const items = await response.json();
                this.dictDepart = items.depart;
                this.totalDepart = items.totals_dep;
                this.$set(this.totalDepart, 'rp1', this.totalDepart.request1);
                this.$set(this.totalDepart, 'rp2', this.totalDepart.request2);
                this.$set(this.totalDepart, 'rp3', this.totalDepart.request3);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictDepParam', error.toString());
            }
        },
        async loadDictEcon() {
            try {
                const response = await fetch('/api-py/rash-econ-form/' + this.curFilter.year + '/' + this.curFilter.region + '/' + this.curFilter.variant + '/' + this.dataType);
                const items = await response.json();
                this.dictEcon = items.tree;
                this.totalEcon = items.totals;
                this.spfList = items.spfList;
                this.selEconSpf = null;
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictEcon', error.toString());
            }
        },
        loadFilters(map, view) {
            try {
                if (this.filterFunc.opFilter){
                    this.filterFunc.abpFilter = [];
                    for (const [key, value] of map.entries()) {
                        this.filterFunc.abpFilter.push(value);
                    }
                    if (this.filterFunc.abpFilter.length === 0) {
                        this.filterFunc.abp = this.filterFunc.abpFilter[0].abp;
                    }

                    this.filterFunc.prgList = this.filterFunc.abpFilter[0].prgList;
                    this.filterFunc.prg = this.filterFunc.prgList[0].prg;

                    this.filterFunc.pprList = this.filterFunc.abpFilter[0].prgList[0].pprList;
                    this.filterFunc.ppr = this.filterFunc.pprList[0].ppr;
                    this.filterFunc.opFilter = false;
                }
                if (this.filterDepart.opFilter) {
                    this.filterDepart.abpFilter = [];
                    for (const [key, value] of map.entries()) {
                        this.filterDepart.abpFilter.push(value);
                    }
                    if (this.filterDepart.abpFilter.length === 0) {
                        this.filterDepart.abp = this.filterDepart.abpFilter[0].abp;
                    }

                    this.filterDepart.prgList = this.filterDepart.abpFilter[0].prgList;
                    this.filterDepart.prg = this.filterDepart.prgList[0].prg;

                    this.filterDepart.pprList = this.filterDepart.abpFilter[0].prgList[0].pprList;
                    this.filterDepart.ppr = this.filterDepart.pprList[0].ppr;
                    this.filterDepart.opFilter = false;
                }
                if (view === 2) {
                    this.filterEcon.spf = this.filterDepart.pprList[0].ppr;

                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки loadFilters', error.toString());
            }
        },
        updateABP() {
            try {
                if (this.selecteds === '0') {
                    this.filterFunc.abp = this.selectedAbp.abp;
                    this.selectedPrg = this.selectedAbp.prgList[0];
                    this.selectedPpr = this.selectedAbp.prgList[0].pprList[0];
                    this.filterFunc.prg = this.selectedAbp.prgList[0].prg;
                    this.filterFunc.ppr = this.selectedAbp.prgList[0].pprList[0].ppr;
                } else if (this.selecteds === '1') {
                    this.filterDepart.abp = this.selectedAbp.abp;
                    this.selectedPrg = this.selectedAbp.prgList[0];
                    this.selectedPpr = this.selectedAbp.prgList[0].pprList[0];
                    this.filterDepart.prg = this.selectedAbp.prgList[0].prg;
                    this.filterDepart.ppr = this.selectedAbp.prgList[0].pprList[0].ppr;
                }
                this.isLoading = false;
            } finally {
            }
        },
        updatePRG() {
            this.selectedPpr = this.selectedPrg.pprList[0];
            if (this.selecteds === '0') {
                this.filterFunc.prg = this.selectedPrg.prg;
                this.filterFunc.ppr = this.selectedPpr.ppr;
            } else if (this.selecteds === '1') {
                this.filterDepart.prg = this.selectedPrg.prg;
                this.filterDepart.ppr = this.selectedPpr.ppr;
            }
            this.isLoading = false;
        },
        updatePPR() {
            if (this.selecteds === '0') {
                this.filterFunc.ppr = this.selectedPpr.ppr;
            } else if (this.selecteds === '1') {
                this.filterDepart.ppr = this.selectedPpr.ppr;
            }
            this.isLoading = false;
        },
        updateVersion() {
            if (this.selecteds === '0') {
                this.filterFunc.version = this.version;
            } else if (this.selecteds === '1') {
                this.filterDepart.version = this.version;
            } else {
                this.filterEcon.version = 0;
            }
            this.chgData();
        },
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            let refItem;
            switch (refName) {
                case 'bcPlanYear':
                    refItem = this.$refs.bcPlanYear;
                    break;
                case 'bcRegion':
                    refItem = this.$refs.bcRegion;
                    break;
                case 'bcVariant':
                    refItem = this.$refs.bcVariant;
                    break;
                case 'bcVersion':
                    refItem = this.$refs.bcVersion;
                    break;
                case 'bcDevelop':
                    refItem = this.$refs.bcDevelop;
                    break;
                case 'bcAbp':
                    refItem = this.$refs.bcAbp;
                    break;
                case 'bcPrg':
                    refItem = this.$refs.bcPrg;
                    break;
                case 'bcPpr':
                    refItem = this.$refs.bcPpr;
                    break;
                case 'bcSpf':
                    refItem = this.$refs.bcSpf;
                    break;
                default: break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        chgData() {
            const data = {
                year: this.curFilter.year,
                dataType: this.dataType,
                region: this.curFilter.region,
                version: this.curFilter.version,
                variant: this.curFilter.variant,
                view: this.selecteds,
                develop: this.develop,
                abp: this.curFilter.abp,
                prg: this.curFilter.prg,
                ppr: this.curFilter.ppr,
                spf: this.selEconSpf
            };
            this.$emit('chgData', data);
        },
        periodChange() {
            this.isLoading = false;
            this.curYear = this.planPeriod.year;
            if (this.selecteds === '0') {
                this.filterFunc.year = this.planPeriod.year;
                this.selRegion = this.listReg[0];
                this.filterFunc.region = this.listReg[0].code;
                if (this.variantLst.length !== 0) {
                    this.curVariant = this.variantLst[0];
                    this.filterFunc.variant = this.variantLst[0].variant;
                }
                this.filterFunc.opFilter = true;
            }
            if (this.selecteds === '1') {
                this.filterDepart.year = this.planPeriod.year;
                this.selRegion = this.listReg[0];
                this.filterDepart.region = this.listReg[0].code;
                if (this.variantLst.length !== 0) {
                    this.curVariant = this.variantLst[0];
                    this.filterDepart.variant = this.variantLst[0].variant;
                }
                this.filterDepart.opFilter = true;
            }
            if (this.selecteds === '2') {
                this.filterEcon.year = this.planPeriod.year;
                this.selRegion = this.listReg[0];
                this.filterEcon.region = this.listReg[0].code;
                if (this.variantLst.length !== 0) {
                    this.curVariant = this.variantLst[0];
                    this.filterEcon.variant = this.variantLst[0].variant;
                }
            }
            this.loadVariants();
        },
        regionChange() {
            this.isLoading = false;
            if (this.selecteds === '0') {
                this.filterFunc.region = this.selRegion.code;
                this.filterFunc.opFilter = true;
            }
            if (this.selecteds === '1') {
                this.filterDepart.region = this.selRegion.code;
                this.filterDepart.opFilter = true;
            }
            if (this.selecteds === '2') {
                this.filterEcon.region = this.selRegion.code;
            }
            this.loadVariants();
        },
        variantChange() {
            this.isLoading = false;
            if (this.selecteds === '0') {
                this.filterFunc.variant = this.curVariant.variant_uuid;
                this.filterFunc.opFilter = true;
            }
            if (this.selecteds === '1') {
                this.filterDepart.variant = this.curVariant.variant_uuid;
                this.filterDepart.opFilter = true;
            }
            if (this.selecteds === '2') {
                this.filterEcon.variant = this.curVariant.variant_uuid;
            }
        },
        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (!txt) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        }
},
    watch: {
        userId: async function (newVal, oldVal) {
            if (newVal!== oldVal) {
                await this.loadRegions();
                this.filterFunc.region = this.selRegion.code;
                this.filterDepart.region = this.selRegion.code;
                this.filterEcon.region = this.selRegion.code;
                await this.loadVariants();
                if (this.curVariant !== null) {
                    this.filterFunc.variant = this.curVariant.variant_uuid;
                    this.filterDepart.variant = this.curVariant.variant_uuid;
                    this.filterEcon.variant = this.curVariant.variant_uuid;
                }
                await this.loadData();
            }
        }
    }
};
</script>