<template>
    <div>
<!--        <div>-->
<!--            <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>-->
<!--        </div>-->
        <template v-if="region !== null">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                :tbody-tr-class="rowClass"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                :tbody-transition-props="transProps"
                no-border-collapse>

                <template #thead-top="data">
                    <b-tr>
                        <b-th v-bind:colspan="totalHead"></b-th>
                        <b-th colspan="3" v-if="dataType === 1" class="text-center">{{ 'Уточненный план на 1 апреля ' + (parseInt(year) - 1) + ' без учета ЦТ из РБ' }}</b-th>
                        <b-th colspan="3" v-else class="text-center">{{ 'Уточненный план на 1 апреля ' + (parseInt(year) - 1) }}</b-th>
                        <b-th colspan="3" v-if="dataType === 1" class="text-center">{{ 'Прогноз на ' + (parseInt(year)) + ' год' }}</b-th>
                        <b-th colspan="3" v-else class="text-center">{{ 'Бюджет на ' + (parseInt(year)) + ' год' }}</b-th>
                        <b-th colspan="6" class="text-center">{{ 'Рост к ' + (parseInt(year) - 1) + ' году' }}</b-th>
                        <b-th colspan="3" v-if="dataType === 1" class="text-center">{{ 'Прогноз на ' + (parseInt(year) + 1) + ' год' }}</b-th>
                        <b-th colspan="3" v-else class="text-center">{{ 'Бюджет на ' + (parseInt(year) + 1) + ' год' }}</b-th>
                        <b-th colspan="3" v-if="dataType === 1" class="text-center">{{ 'Прогноз на ' + (parseInt(year) + 2) + ' год' }}</b-th>
                        <b-th colspan="3" v-else class="text-center">{{ 'Бюджет на ' + (parseInt(year) + 2) + ' год' }}</b-th>
                    </b-tr>
                    <b-tr>
                        <b-th v-bind:colspan="totalHead"></b-th>
                        <b-th></b-th>
                        <b-th colspan="2" class="text-center">{{ 'в том числе:' }}</b-th>
                        <b-th colspan="1"></b-th>
                        <b-th colspan="2" class="text-center">{{ 'в том числе:' }}</b-th>
                        <b-th colspan="2" class="text-center">{{ 'Всего расходы' }}</b-th>
                        <b-th colspan="2" class="text-center">{{ 'текущий бюджет' }}</b-th>
                        <b-th colspan="2" class="text-center">{{ 'бюджет развития' }}</b-th>
                        <b-th colspan="3"></b-th>
                        <b-th colspan="3"></b-th>
                    </b-tr>
                </template>
                <template #head(action)="scope">
<!--                    <b-button @click="openAll()">-->
<!--                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>-->
<!--                        <i class="icon icon-chevron-circle" v-if="!open"></i>-->
<!--                    </b-button>-->
                </template>
                <template #head(abp)="scope">
                    <div>АБП</div>
                </template>
                <template #head(prg)="scope">
                    <div>БП</div>
                </template>
                <template #head(ppr)="scope">
                    <div>БПП</div>
                </template>
                <template #head(spf)="scope">
                    <div>СП</div>
                </template>
                <template #head(name_ru)="scope">
                    <div>Наименование</div>
                </template>
                <template #head(receipt_prev)="scope">
                    <div>{{ 'Исполнение за ' + (parseInt(year) - 2) + ' год' }}</div>
                </template>
                <template #head(grow2)="scope">
                    <div>{{ '% роста к ' + (parseInt(year) + 1) + ' году' }}</div>
                </template>
                <template #head(deviation2)="scope">
                    <div>{{ 'отклонение к ' + (parseInt(year) + 1) + ' году' }}</div>
                </template>
                <template #head(grow3)="scope">
                    <div>{{ '% роста к ' + (parseInt(year) + 2) + ' году' }}</div>
                </template>
                <template #head(deviation3)="scope">
                    <div>{{ 'отклонение к ' + (parseInt(year) + 2) + ' году' }}</div>
                </template>
                <template #top-row="data" v-if="totals !== null">
                    <td class="text-center" v-bind:colspan="totalSpan">{{ totals.name_ru }}</td>
                    <td class="text-right">{{ $n(toNum(totals.receipt_prev)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.plan)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.plan_0)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.plan_1)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp1)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp1_0)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp1_1)) }}</td>
                    <td class="text-right">{{ totals.plan > 0 ? $n(toNum((totals.rp1 / totals.plan) * 100)) : 0 }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp1 - totals.plan)) }}</td>
                    <td class="text-right">{{ totals.plan_0 > 0 ? $n(toNum((totals.rp1_0 / totals.plan_0) * 100)) : 0 }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp1_0 - totals.plan_0)) }}</td>
                    <td class="text-right">{{ totals.plan_1 > 0 ? $n(toNum((totals.rp1_1 / totals.plan_1) * 100)) : 0 }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp1_1 - totals.plan_1)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp2)) }}</td>
                    <td class="text-right">{{ totals.rp2 > 0 ? $n(toNum((totals.rp2 / totals.rp1) * 100)) : 0 }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp2 - totals.rp1)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp3)) }}</td>
                    <td class="text-right">{{ totals.rp2 > 0 ? $n(toNum((totals.rp3 / totals.rp2) * 100)) : 0 }}</td>
                    <td class="text-right">{{ $n(toNum(totals.rp3 - totals.rp2)) }}</td>
                </template>

                <template #cell(action)="data">
                    <b-button v-if="data.item.type === 3" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(abp)="data">
                    <div v-if="data.item.type === 3" class="text-right">{{ data.value }}</div>
                    <b-button v-if="(data.item.type === 4) && data.item.hasChild"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(prg)="data">
                    <div v-if="data.item.type === 4" class="text-right">{{ data.value }}</div>
                    <b-button v-if="(data.item.type === 5) && data.item.hasChild && version === 0"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(ppr)="data">
                    <div v-if="data.item.type === 5" class="text-right">{{ data.value }}</div>
                    <b-button v-if="(data.item.type === 6) && data.item.hasChild && version === 0"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(spf)="data">
                    <div v-if="data.item.type === 6" class="text-right">{{ data.value }}</div>
                </template>
                <template #cell(name_ru)="data">
                    <div>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">
                        {{ $n(toNum(data.value)) }}
                    </div>
                </template>
            </b-table>
        </template>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: 'CostDepart',
    props: {
        year: Number,
        region: String,
        version: Number,
        totals: Object,
        dictTree: null,
        dataType: Number,
        selectedAbp: null,
        selectedPrg: null,
        selectedPpr: null
    },
    data() {
        return {
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                     class: 'toggle-show'
                },
                {
                    key: 'abp',
                    label: 'АБП',
                     class: 'toggle-show'
                },
                {
                    key: 'prg',
                    label: 'БП',
                     class: 'toggle-show'
                },
                {
                    key: 'ppr',
                    label: 'БПП',
                     class: 'toggle-show'
                },
                {
                    key: 'spf',
                    label: 'СП',
                    thClass: 'true',
                    tdClass: 'true',
                     class: 'toggle-show'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'receipt_prev',
                    label: ''
                },
                {
                    key: 'plan',
                    label: 'Итого расходы бюджета',
                    variant: 'info'
                },
                {
                    key: 'plan_0',
                    label: 'текущий бюджет'
                },
                {
                    key: 'plan_1',
                    label: 'бюджет развития'
                },
                {
                    key: 'rp1',
                    label: 'Итого расходы бюджета',
                    variant: 'info'
                },
                {
                    key: 'rp1_0',
                    label: 'текущий бюджет'
                },
                {
                    key: 'rp1_1',
                    label: 'бюджет развития'
                },
                {
                    key: 'grow1',
                    label: '%'
                },
                {
                    key: 'deviation1',
                    label: 'сумма'
                },
                {
                    key: 'grow1_0',
                    label: '%'
                },
                {
                    key: 'deviation1_0',
                    label: 'сумма'
                },
                {
                    key: 'grow1_1',
                    label: '%'
                },
                {
                    key: 'deviation1_1',
                    label: 'сумма'
                },
                {
                    key: 'rp2',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow2',
                    label: ''
                },
                {
                    key: 'deviation2',
                    label: ''
                },
                {
                    key: 'rp3',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow3',
                    label: ''
                },
                {
                    key: 'deviation3',
                    label: ''
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            selectedTree: [],
            budgetForm: [],
            budgetFormTemp: [],
            budgetDataMap: new Map(),
            open: true,
            totalSpan: 6,
            totalHead: 7
        };
    },
    created() {
        this.$watch('region', this.prepareForm);
        this.$watch('version', this.developVersion);
    },
    async mounted() {
        await this.prepareForm();

    },
    beforeUpdate() {
        for (const row of this.budgetForm) {
            if (this.version === 0) {
                if ([3, 4, 5].includes(row.type) && row.hasChild) {
                    row._rowVariant = 'info';
                } else {
                    row._rowVariant = 'light ';
                }
            } else {
                if ([3, 4].includes(row.type)) {
                    row._rowVariant = 'info';
                } else {
                    row._rowVariant = 'light ';
                }
            }
        }
        if (this.version === 0) {
            this.totalHead = 7;
            this.totalSpan = 6;
            this.tableFields[4].thClass = '';
            this.tableFields[4].tdClass = '';
        } else {
            this.tableFields[4].thClass = 'd-none';
            this.tableFields[4].tdClass = 'd-none';
            this.totalHead = 6;
            this.totalSpan = 5;
        }
    },

    methods: {
        createTable(elem, parentId) { // создание таблицы на основе дерева
            const that = this;
            const item = Object.assign({}, elem); //JSON.parse(JSON.stringify(elem)); //
            that.$set(item, 'parent_id', parentId);
            that.$set(item, 'visible', true);
            const par = that.budgetDataMap.get(item.parent_id);
            if (par) {
                if (par.hasOwnProperty('newchild')) {
                    that.$set(par.newchild, par.newchild.length, item);
                } else {
                    that.$set(par, 'newchild', []);
                    that.$set(par.newchild, par.newchild.length, item);
                }
            }
            Object.defineProperty(item, 'parent', {
                get: function () {
                    const par = that.budgetDataMap.get(item.parent_id);
                    if (par) {
                        return par;
                    }
                    return null;
                }
            });
            this.changeVersion(item);
            Object.defineProperty(item, 'grow1', {
                get: function () {
                    return (item.plan === null || item.plan === 0 ? 0 : that.toNum((item.rp1 / item.plan) * 100));
                }
            });
            Object.defineProperty(item, 'deviation1', {
                get: function () {
                    return (item.rp1 - item.plan);
                }
            });
            Object.defineProperty(item, 'grow1_0', {
                get: function () {
                    return (item.plan_0 === null || item.plan_0 === 0 ? 0 : that.toNum((item.rp1_0 / item.plan_0) * 100));
                }
            });
            Object.defineProperty(item, 'deviation1_0', {
                get: function () {
                    return (item.rp1_0 - item.plan_0);
                }
            });
            Object.defineProperty(item, 'grow1_1', {
                get: function () {
                    return (item.plan_1 === null || item.plan_1 === 0 ? 0 : that.toNum((item.rp1_1 / item.plan_1) * 100));
                }
            });
            Object.defineProperty(item, 'deviation1_1', {
                get: function () {
                    return (that.toNum(item.rp1_1) - that.toNum(item.plan_1));
                }
            });
            Object.defineProperty(item, 'grow2', {
                get: function () {
                    return (item.rp1 === null || item.rp1 === 0 ? 0 : that.toNum((item.rp2 / item.rp1) * 100));
                }
            });
            Object.defineProperty(item, 'deviation2', {
                get: function () {
                    return (that.toNum(item.rp2) - that.toNum(item.rp1));
                }
            });
            Object.defineProperty(item, 'grow3', {
                get: function () {
                    return (item.rp2 === null || item.rp2 === 0 ? 0 : that.toNum((item.rp3 / item.rp2) * 100));
                }
            });
            Object.defineProperty(item, 'deviation3', {
                get: function () {
                    return (item.rp3 - item.rp2);
                }
            });
            that.$set(item, 'index', that.budgetFormTemp.length);
            that.$set(that.budgetFormTemp, that.budgetFormTemp.length, item);
            that.budgetDataMap.set(item.id, item);
            if (item.hasOwnProperty('child') && (item.child.length > 0)) {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);
            }
            if (item.hasChild) {
                for (const ch of item.child) {
                    that.createTable(ch, item.id);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        changeVersion(item) {
            if (this.version === 0) {
                item.rp1 = item.request1_;
                item.rp1_0 = item.request1_0;
                item.rp1_1 = item.request1_1;
                item.rp2 = item.request2_;
                item.rp3 = item.request3_;
                if (item.type === 6) {
                    item.visible = true;
                }
                if ([3, 4, 5].includes(item.type) && item.hasChild) {
                    item._rowVariant = 'info';
                } else {
                    item._rowVariant = 'light ';
                }
            } else {
                item.rp1 = item.plan1_;
                item.rp1_0 = item.plan1_0;
                item.rp1_1 = item.plan1_1;
                item.rp2 = item.plan2_;
                item.rp3 = item.plan3_;
                if (item.type === 6) {
                    item.visible = false;
                }
                if ([3, 4].includes(item.type)) {
                    item._rowVariant = 'info';
                } else {
                    item._rowVariant = 'light ';
                }
            }

            this.open = true;
            this.openAll();
        },
        versionChange() {
            if (this.version !== 0) {
                this.tableFields[4].thClass = 'd-none';
                this.tableFields[4].tdClass = 'd-none';
                this.totalHead = 6;
                this.totalSpan = 5;
            } else {
                this.totalHead = 7;
                this.totalSpan = 6;
                this.tableFields[4].thClass = '';
                this.tableFields[4].tdClass = '';
            }
        },
        developVersion() {
            this.changeVersion(this.totals);
            for (const row of this.budgetFormTemp) {
                this.changeVersion(row);
            }
            for (const row of this.budgetForm) {
                this.changeVersion(row);
            }
            this.versionChange();
            this.deleteNulls();
            this.open = true;
            this.openAll();
        },
        async prepareForm() {
            this.budgetForm = [];
            this.budgetFormTemp = [];
            this.budgetDataMap = new Map();
            for (const item of this.dictTree) {
                await this.createTable(item, 0);
            }
            for (const row of this.budgetFormTemp) {
                this.changeVersion(row);
            }
            for (const item of this.budgetFormTemp) {
                if (item.type == 3) {
                    item.open = false;
                    this.budgetForm.push(item)
                }
            }

            this.changeVersion(this.totals);
        }, // подготовка отображения данных

        async openAll() {
            this.open = !this.open;

            for (const row of this.budgetForm) {
                if ([3, 4].includes(row.type)) {
                    row.open = this.open;
                    row.visible = this.open;
                } else {
                    if ([6].includes(row.type)) {
                        row.visible = false;
                        row.open = false;
                    } else {
                        row.open = false;
                        row.visible = this.open;
                    }
                }
                if ([3].includes(row.type)) {
                    row.visible = true;
                }
            }
        }, // открывает.закрывает все ветки
        async deleteNulls() {
            for (const row of this.budgetForm) {
                if (parseFloat(row.receipt_prev)
                    + parseFloat(row.plan)
                    + parseFloat(row.rp1)
                    + parseFloat(row.rp2)
                    + parseFloat(row.rp3) === 0) {
                    row.visible = false;
                }
            }
        },
        compare_spf(a, b) {
            if (a.spf < b.spf) {
                return -1;
            }
            if (a.spf > b.spf) {
                return 1;
            }
            return 0;
        },
        compare_ppr(a, b) {
            if (a.ppr < b.ppr) {
                return -1;
            }
            if (a.ppr > b.ppr) {
                return 1;
            }
            return 0;
        },
        compare_prg(a, b) {
            if (a.prg < b.prg) {
                return -1;
            }
            if (a.prg > b.prg) {
                return 1;
            }
            if (a.ppr === undefined) {
                return -1;
            }
            if (b.ppr === undefined) {
                return 1;
            }
            return 0;
        },
        compare_abp(a, b) {
            if (a.abp < b.abp) {
                return -1;
            }
            if (a.abp > b.abp) {
                return 1;
            }
            if (a.prg === undefined) {
                return -1;
            }
            if (b.prg === undefined) {
                return 1;
            }
            return 0;
        },
        compare_null(a, b) {
            if ((a.abp === undefined) || (b.abp !== undefined)) {
                return -1;
            }
            return 1;
        },

        openChilds(parent, bool) {
            parent.open = (bool === undefined ? !parent.open : bool);
            if (parent.newchild) {
                let dat = parent.newchild;
                dat = dat.sort(this.compare_spf);
                dat = dat.sort(this.compare_ppr);
                dat = dat.sort(this.compare_prg);
                dat = dat.sort(this.compare_abp);
                dat = dat.sort(this.compare_null);
                for (const row of dat) {
                    if ((parent.id === row.parent_id)) {
                        const ind = this.budgetForm.indexOf(parent);
                        if (this.budgetForm.indexOf(row) < 0) {
                            this.budgetForm.splice(ind + 1, 0, row);
                        }
                        if ([3, 4].includes(row.type)) {
                            this.openChilds(row, parent.open);
                            row.visible = parent.open;
                            row.open = parent.open;
                        }
                        if ([6].includes(row.type)) {
                            row.visible = false;
                        }
                        if ([5].includes(row.type)) {
                            row.visible = parent.open;
                            row.open = false;
                            this.openChilds(row, row.open);
                        }
                        if (parent.type === 5) {
                            this.openChilds(row, parent.open);
                            row.visible = parent.open;
                            row.open = parent.open;
                        }
                        if ((this.budgetForm.indexOf(row) > 0) && (!row.visible)) {
                            this.budgetForm.splice(this.budgetForm.indexOf(row), 1);
                        }
                    }
                }
            }
            this.deleteNulls();
        }, // открывает/закрывает ветку до конечного элемента

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },

        toStr(value) {
            if (typeof (value) === 'string') {
                return value;
            }
            if (typeof (value) === 'number') {
                return value.toString();
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 'true' : 'false');
            }
            if (typeof (value) === 'undefined' || isNaN(value) || isFinite(value) || (value === null)) {
                return '';
            }
        }
    }
};
</script>

<style scoped>
.my-hidden {
    visibility: collapse;
}
</style>